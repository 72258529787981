import React, { useEffect, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import ReCAPTCHA from 'react-google-recaptcha';
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { useIsInViewport, useWindowSize } from "../../../hooks";
import { Box, Button, Form, InputField } from "../../common";
import "./ApplyNow.scss";

export const validationSchema = object({
  firstname: string().required("First name is a required field"),
  lastname: string().required("Last name is a required field"),
  email: string()
    .email("Please enter a valid email")
    .trim()
    .required("E-mail is a required field"),
});

const ApplyNow = ({ title, short, formTitle, formSubtitle }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isInViewportThreshold = windowSize.width > 768 ? 0.5 : 0.2;
  const sectionRef = useRef(null);
  const isInViewport = useIsInViewport(sectionRef, isInViewportThreshold);
  const [showAnimation, setShowAnimation] = useState(false);
  
  const recaptchaRef = useRef(null);
  
  const handleSubmit = async (data) => {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    
    try {
      const payload = {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        token: token
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
     
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      TagManager.dataLayer({
        dataLayer: {
          event: "submitted_first_form",
          category: "Form Submission",
          action: "submit",
          label: "Submitted First Form",
          value: 1,
        },
      });

      window.ttq.track("submitted_first_form");

      navigate("/funding-application#register", {
        state: data,
      });
    } catch (error) {
      console.log(error)
      console.error("An error occurred:", error);
      enqueueSnackbar(error.message || "An unexpected error occurred.", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => setShowAnimation(isInViewport), 50);
    }
  }, [isInViewport]);

  useEffect(() => {
    if (window.location.hash === "#apply-now") {
      setTimeout(() => {
        sectionRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, []);

  return (
    <section
      id="apply-now"
      className={`section section--applyNow${showAnimation ? " onScreen" : ""}`}
      ref={sectionRef}
    >
      <div className="section__innerWrapper">
        <article
          className="overDeco"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h2 className="animateIn">{title}</h2>
          <p className="animateIn" style={{ marginTop: "4rem" }}>
            {short}
          </p>
        </article>
        <Box className={`formBox overDeco`} animateIn>
          <div className="header">
            <h3>
              { formTitle } {formSubtitle ? <span>{ formSubtitle }</span> : ''}
            </h3>

            <lord-icon
              src="/animated-icons/hand-clock.json"
              trigger="loop"
              delay="1000"
              colors="primary:#f9df1c,secondary:#7adba1,tertiary:#657df1,quaternary:#ff9fb6"
              style={{ width: "11.5rem", height: "11.5rem" }}
            ></lord-icon>
          </div>
          <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`} 
              size="invisible"
            />
            <InputField
              id="firstname"
              name="firstname"
              type="text"
              placeholder="Input first name"
              labelAbove={"First Name"}
            />
            <InputField
              id="lastname"
              name="lastname"
              type="text"
              placeholder="Input last name"
              labelAbove={"Last Name"}
            />
            <InputField
              id="email"
              name="email"
              placeholder="Input e-mail"
              labelAbove={"E-mail"}
            />
            <Button
              className="button primary xl full-width"
              label="Get Started"
              // type="submit"
              style={{ marginTop: "1.6rem" }}
            />
          </Form>
        </Box>
      </div>
    </section>
  );
};

export default ApplyNow;
